import React, {useEffect, useState} from 'react';
import {
  Button,
  EmptyBox,
  Line,
  Popup,
  Preloader,
  Text,
  Title,
  Wrapper
} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {useIntegrationContext} from '../integration-context';
import * as AppEnv from 'app-env';

import Instance from './instance/instance';

interface SpamProtect {
  isSpamProtectActive: boolean;
  setIsSpamProtectActive: AppEnv.SetState<boolean>;
}

const SpamProtect = ({
  isSpamProtectActive,
  setIsSpamProtectActive
}: SpamProtect) => {
  const [instanceList, setInstanceList] = useState<AppEnv.Instance[]>();

  const {getInstanceList, integration} = useIntegrationContext();
  const {t} = useTranslation();

  const getInstances = async () => {
    const res = await getInstanceList();
    setInstanceList(res);
  };

  useEffect(() => {
    if (!isSpamProtectActive) return;
    getInstances();
  }, [isSpamProtectActive]);

  return (
    <Popup
      isActive={isSpamProtectActive}
      onClick={() => setIsSpamProtectActive(false)}
    >
      <Title.H2 style={{marginBottom: 4}}>{t`Spam protection`}</Title.H2>
      <Text style={{marginBottom: 16}}>{integration.domain}</Text>

      {instanceList ? (
        instanceList.length > 0 ? (
          <Wrapper gap={8} isColumn>
            {instanceList.map(instance => (
              <>
                {instance.phone && (
                  <Instance instance={instance} key={instance.id} />
                )}
              </>
            ))}
          </Wrapper>
        ) : (
          <EmptyBox />
        )
      ) : (
        <Preloader />
      )}

      <Line />
      <Button onClick={() => setIsSpamProtectActive(false)}>{t`Done`}</Button>
    </Popup>
  );
};

export default SpamProtect;
