import React from 'react';
import {Radio, Title} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

type Value = '-1' | '0' | '1' | '2';

interface Option {
  label: string;
  value: Value;
}

const IsDeleted = () => {
  const [field, , helpers] = useField<Value>('is_deleted');
  const {t} = useTranslation();

  const options: Option[] = [
    {label: t`All payments`, value: '-1'},
    {label: t`Active`, value: '0'},
    {label: t`Refund`, value: '1'},
    {label: t`Recalculation`, value: '2'}
  ];

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Status`}</Title.H3>
      <Radio {...field} options={options} onChange={helpers.setValue} />
    </div>
  );
};

export default IsDeleted;
