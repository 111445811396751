import React from 'react';
import {Confirmation, TableCell} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {useAppContext} from 'app-context';
import {usePaymentContext} from '../payment-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

import PopupProvider from './popup-provider/popup-provider';

interface Refund {
  updatePayment: (arg: AppEnv.Payment) => void;
}

const Refund = ({updatePayment}: Refund) => {
  const {pushNotification} = useAppContext();
  const {isRefundActive, payment, setIsRefundActive} = usePaymentContext();

  const {putBillings} = useRequest();
  const {t} = useTranslation();

  const cancelRefund = async () => {
    const {data} = await putBillings(payment.id, {is_deleted: 0});
    if (!data) return true;

    updatePayment(data);
    setIsRefundActive(false);
    pushNotification(t`The refund was canceled.`);

    return true;
  };

  const handleConfirm = async (res: boolean) => {
    if (res) return cancelRefund();

    setIsRefundActive(false);
    return true;
  };

  const isConfirmActive = isRefundActive && payment.is_deleted == 1;
  const isPopupActive = isRefundActive && payment.is_deleted != 1;

  return (
    <TableCell isIcon>
      <Confirmation
        isActive={isConfirmActive}
        onClick={handleConfirm}
        title={t`Are you sure you want to cancel this refund?`}
      />

      <PopupProvider
        isPopupActive={isPopupActive}
        updatePayment={updatePayment}
      />
    </TableCell>
  );
};

export default Refund;
