import {useTranslation} from 'react-i18next';

import {Roles} from 'app-enums';
import {useAppContext} from 'app-context';
import {usePaymentContext} from 'pages/billing/payment-list/payment/payment-context';

const useStatuses = () => {
  const {client} = useAppContext();

  const {payment, setIsRecalcActive, setIsRefundActive} = usePaymentContext();
  const {t} = useTranslation();

  const isRole =
    !!client?.access_level && client.access_level >= Roles.SUPMODER;

  if (!isRole) return [];

  const cancelRecalc = {
    onClick: () => setIsRecalcActive(true),
    value: t`Cancel recalculation`
  };

  const cancelRefund = {
    onClick: () => setIsRefundActive(true),
    value: t`Cancel refund`
  };

  const recalc = {
    onClick: () => setIsRecalcActive(true),
    value: t`Recalculate`
  };

  const refund = {
    onClick: () => setIsRefundActive(true),
    value: t('Refund', {context: 'verb'})
  };

  if (payment.is_deleted == 2) return [refund, cancelRecalc];
  else if (payment.is_deleted == 1) return [cancelRefund, recalc];

  return [refund, recalc];
};

export default useStatuses;
