import React, {useContext} from 'react';
import * as AppEnv from 'app-env';

interface PaymentContext {
  isRecalcActive: boolean;
  isRefundActive: boolean;
  isTransactionActive: boolean;
  payment: AppEnv.Payment;
  setIsRecalcActive: AppEnv.SetState<boolean>;
  setIsRefundActive: AppEnv.SetState<boolean>;
  setIsTransactionActive: AppEnv.SetState<boolean>;
}

const PaymentContext = React.createContext<PaymentContext>(
  {} as PaymentContext
);

export const usePaymentContext = () => useContext(PaymentContext);
export default PaymentContext;
