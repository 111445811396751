import React from 'react';
import {
  Popup,
  Title,
  Line,
  Wrapper,
  Button,
  useCopyToClipboard
} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {Code} from 'components';
import {useAppContext} from 'app-context';
import {usePaymentContext} from '../payment-context';
import * as AppEnv from 'app-env';

interface Transaction {
  payment: AppEnv.Payment;
}

const Transaction = () => {
  const {pushNotification} = useAppContext();

  const {isTransactionActive, payment, setIsTransactionActive} =
    usePaymentContext();

  const copyToClipboard = useCopyToClipboard();
  const {t} = useTranslation();

  const handleClick = async () => {
    const res = await copyToClipboard(payment.paymentData || '{}');
    pushNotification(res.message);
  };

  return (
    <Popup
      width={790}
      isActive={isTransactionActive}
      onClick={() => setIsTransactionActive(false)}
    >
      <Title.H2 style={{marginBottom: 16}}>{t`Transaction`}</Title.H2>
      <Code code={payment.paymentData || ''} />
      <Line />

      <Wrapper gap={8}>
        <Button onClick={handleClick}>{t`Copy`}</Button>

        <Button color="white" onClick={() => setIsTransactionActive(false)}>
          {t`Close`}
        </Button>
      </Wrapper>
    </Popup>
  );
};

export default Transaction;
