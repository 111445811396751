import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {Roles} from 'app-enums';
import {useAppContext} from 'app-context';
import {usePaymentContext} from 'pages/billing/payment-list/payment/payment-context';

const useEvents = () => {
  const {client} = useAppContext();
  const {payment} = usePaymentContext();

  const {t} = useTranslation();
  const navigate = useNavigate();

  const isRole = !!client?.access_level && client.access_level >= Roles.MODER;
  if (!isRole) return null;

  const events = {
    onClick: () =>
      navigate(
        `/events?filter_category=potreblen_yslug&filter_category_id=${payment.id}`,
        {state: {referrer: window.location.pathname + window.location.search}}
      ),
    value: t`Open events`
  };

  return events;
};

export default useEvents;
