import {useTranslation} from 'react-i18next';
import {usePaymentContext} from 'pages/billing/payment-list/payment/payment-context';

const useTransaction = () => {
  const {payment, setIsTransactionActive} = usePaymentContext();
  const {t} = useTranslation();

  if (!payment.paymentData) return null;

  const transaction = {
    onClick: () => setIsTransactionActive(true),
    value: t`Open transaction`
  };

  return transaction;
};

export default useTransaction;
