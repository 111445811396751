import {useState} from 'react';

import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

const useGetInstanceList = (integration: AppEnv.Integration) => {
  const [instanceList, setIsInstanceList] = useState<AppEnv.Instance[]>();
  const {fetchInstances} = useRequest();

  const requestInstances = async (
    instances: AppEnv.Instance[] = [],
    page = 1
  ): Promise<AppEnv.Instance[]> => {
    const {data, headers} = await fetchInstances<AppEnv.Instance[]>({
      filter: {crm_id: {value: String(integration.id)}},
      page
    });

    const totalCount = headers?.['x-pagination-total-count'];
    if (!data || !totalCount) return instances;

    const res = [...instances, ...data];

    if (res.length < parseInt(totalCount))
      return requestInstances(res, page + 1);

    return res;
  };

  const getInstanceList = async () => {
    if (instanceList) return instanceList;

    const res = await requestInstances();
    setIsInstanceList(res);
    return res;
  };

  return getInstanceList;
};

export default useGetInstanceList;
