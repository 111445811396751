import React from 'react';
import {Confirmation} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {usePaymentContext} from '../payment-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

interface Recalc {
  updatePayment: (data: AppEnv.Payment) => void;
}

const Recalc = ({updatePayment}: Recalc) => {
  const {isRecalcActive, payment, setIsRecalcActive} = usePaymentContext();

  const {putBillings} = useRequest();
  const {t} = useTranslation();

  const recalc = async () => {
    const is_deleted = payment.is_deleted == 2 ? 0 : 2;
    const {data} = await putBillings(payment.id, {is_deleted});
    if (!data) return true;

    setIsRecalcActive(false);
    updatePayment(data);

    return true;
  };

  const handleClick = (res: boolean) => {
    if (res) return recalc();
    setIsRecalcActive(false);
  };

  const title =
    payment.is_deleted == 2
      ? t`Cancel recalculation of this payment?`
      : t`Recalculate this payment?`;

  return (
    <Confirmation
      isActive={isRecalcActive}
      onClick={handleClick}
      title={title}
    />
  );
};

export default Recalc;
