import useEvents from './use-events/use-events';
import useStatuses from './use-statuses/use-statuses';
import useTransaction from './use-transaction/use-transaction';

interface Option {
  onClick: () => void;
  value: string;
}

const useOptions = () => {
  const events = useEvents();
  const statuses = useStatuses();
  const transaction = useTransaction();

  const options = [events, transaction, ...statuses].filter(
    option => option != null
  );

  return options as Option[];
};

export default useOptions;
