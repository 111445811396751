import React, {useEffect, useState} from 'react';
import {MultiSelect, Title} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {useIntegrationContext} from '../../integration-context';
import {useWorkspaceContext} from 'workspace/workspace-context';
import * as AppEnv from 'app-env';
import instanceTypes from '../instance-types';

interface Option {
  value: number;
  label: string;
}

const Connections = () => {
  const {crmList} = useWorkspaceContext();
  const {getInstanceList} = useIntegrationContext();

  const [instanceList, setInstanceList] = useState<AppEnv.Instance[]>();
  const [options, setOptions] = useState<Option[]>();

  const [allField] = useField<boolean>('all');
  const [field, meta, helpers] = useField<number[]>('connections');
  const [newTypeCrmIdField] = useField<number | undefined>('new_type_crm_id');
  const {t} = useTranslation();

  const crm = crmList.find(item => item.id == newTypeCrmIdField.value);

  const updateOptions = () => {
    if (!instanceList) return;

    const fusedInstanceList =
      !crm || crm.title == 'LK'
        ? instanceList
        : instanceList.filter(item => item.version == instanceTypes[crm.title]);

    setOptions(
      fusedInstanceList.map(item => ({
        value: item.id,
        label: `${t`Connection`} ${item.id}`
      }))
    );
  };

  useEffect(() => {
    updateOptions();
  }, [instanceList, newTypeCrmIdField.value]);

  useEffect(() => {
    if (crm?.title != 'LK') helpers.setValue([]);
  }, [newTypeCrmIdField.value]);

  const getInstances = async () => {
    const res = await getInstanceList();
    setInstanceList(res);
  };

  useEffect(() => {
    getInstances();
  }, []);

  useEffect(() => {
    if (allField.value) helpers.setValue([]);
  }, [allField.value]);

  const isDisabled = allField.value || !instanceList;
  const isValid = !(meta.touched && meta.error);

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Connections`}</Title.H3>

      <MultiSelect
        {...field}
        isDisabled={isDisabled}
        isValid={isValid}
        onChange={value => helpers.setValue(value || [])}
        options={options || []}
        placeholder={instanceList ? t`Connections` : t`Loading...`}
      />
    </div>
  );
};

export default Connections;
