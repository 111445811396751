import React from 'react';
import {Button, Confirmation, Icons} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {useAppContext} from 'app-context';
import {usePaymentListContext} from '../../payment-list-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

interface Delete {
  isDeleteActive: boolean;
  payment: AppEnv.Payment;
  setIsDeleteActive: AppEnv.SetState<boolean>;
}

const Delete = ({isDeleteActive, payment, setIsDeleteActive}: Delete) => {
  const {pushNotification} = useAppContext();
  const {getPaymentList} = usePaymentListContext();

  const {deleteBillings} = useRequest();
  const {t} = useTranslation();

  const deletePayment = async () => {
    const {data} = await deleteBillings(payment.id);
    if (typeof data != 'string') return true;

    setIsDeleteActive(false);
    pushNotification(t`The payment has been deleted`);
    setTimeout(() => getPaymentList(), 200);

    return true;
  };

  const handleClick = async (res: boolean) => {
    if (res) return deletePayment();

    setIsDeleteActive(false);
    return true;
  };

  return (
    <>
      <Button
        color="transparent"
        onClick={() => setIsDeleteActive(true)}
        tip={t`Delete`}
      >
        <Icons.Trash />
      </Button>

      <Confirmation
        title={t`Are you sure you want to delete this payment?`}
        isActive={isDeleteActive}
        onClick={handleClick}
      />
    </>
  );
};

export default Delete;
