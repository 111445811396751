import React, {useContext} from 'react';
import * as AppEnv from 'app-env';

interface IntegrationContext {
  getInstanceList: () => Promise<AppEnv.Instance[]>;
  integration: AppEnv.Integration;
}

const IntegrationContext = React.createContext<IntegrationContext>(
  {} as IntegrationContext
);

export const useIntegrationContext = () => useContext(IntegrationContext);
export default IntegrationContext;
