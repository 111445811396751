import React from 'react';
import {Title} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {IntegrationSearchSelect, IntegrationSearchSelectEnv} from 'components';
import {useIntegrationContext} from '../../integration-context';
import {useWorkspaceContext} from 'workspace/workspace-context';
import instanceTypes from '../instance-types';

const NewIntegration = () => {
  const {crmList} = useWorkspaceContext();
  const {integration} = useIntegrationContext();

  const [, newDomainMeta, newDomainHelpers] = useField('new_domain');
  const [, newTypeCrmIdMeta, newTypeCrmIdHelpers] = useField('new_type_crm_id');
  const [newIdField, , newIdHelpers] = useField('new_id');
  const {t} = useTranslation();

  const getIntegrationTypeId = () => {
    const crm = crmList.find(
      item => item.id == integration.integration_type_id
    );

    if (!crm) return undefined;
    else if (crm?.title == 'LK') return undefined;

    const sameTypeCrmList = crmList.filter(item => {
      if (typeof instanceTypes[item.title] == 'object') return true;
      else if (instanceTypes[item.title] == instanceTypes[crm.title])
        return true;
    });

    const integrationTypeId = sameTypeCrmList?.map(item => item.id.toString());
    return integrationTypeId;
  };

  const handleChange = (value: IntegrationSearchSelectEnv.Integration) => {
    newDomainHelpers.setValue(value.domain);
    newIdHelpers.setValue(value.id);
    newTypeCrmIdHelpers.setValue(value.integration_type_id);
  };

  const integrationTypeId = getIntegrationTypeId();

  const isValid = !(
    newDomainMeta.error &&
    newDomainMeta.touched &&
    newTypeCrmIdMeta.error &&
    newTypeCrmIdMeta.touched
  );

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`New integration`}</Title.H3>

      <IntegrationSearchSelect
        integrationTypeId={integrationTypeId}
        isValid={isValid}
        name="new-integration"
        onChange={handleChange}
        value={newIdField.value}
      />
    </div>
  );
};

export default NewIntegration;
