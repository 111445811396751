import React, {useEffect, useState} from 'react';
import {Toggle} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {useIntegrationContext} from '../../integration-context';
import {useRequestKommo} from 'common/hooks';
import {useWorkspaceContext} from 'workspace/workspace-context';
import * as AppEnv from 'app-env';

interface Instance {
  instance: AppEnv.Instance;
}

const Instance = ({instance}: Instance) => {
  const {crmList} = useWorkspaceContext();
  const {integration} = useIntegrationContext();

  const [isFetch, setIsFetch] = useState(false);
  const [isSpamProtect, setIsSpamProtect] = useState<boolean>();

  const crm = crmList.find(crm => crm.id == integration.integration_type_id);

  const {fetchPrefs, postPrefs} = useRequestKommo(crm?.title);
  const {t} = useTranslation();

  const getPrefs = async () => {
    if (!instance.phone) return;

    const res = await fetchPrefs(integration.domain, instance.phone);
    if (!res) return;

    const {data} = res;
    setIsSpamProtect(data.spam_protect);
  };

  useEffect(() => {
    getPrefs();
  }, []);

  const handleChange = async (spam_protect: boolean) => {
    if (!instance.phone) return;
    setIsFetch(true);

    const res = await postPrefs(integration.domain, instance.phone, {
      spam_protect
    });

    setIsFetch(false);
    if (!res) return;

    const {data} = res;
    setIsSpamProtect(data.spam_protect);
  };

  const isDisabled = isFetch || isSpamProtect == undefined;

  return (
    <Toggle
      isDisabled={isDisabled}
      label={`${t`Connection`} ${instance.id}`}
      onChange={handleChange}
      value={!!isSpamProtect}
    />
  );
};

export default Instance;
