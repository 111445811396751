import React from 'react';
import {Button, Line, Popup, SaveButton, Title, Wrapper} from 'whatcrm-core';
import {Form, Formik} from 'formik';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';

import {useAppContext} from 'app-context';
import {useHomeContext} from 'pages/integrations/home/home-context';
import {useIntegrationContext} from '../integration-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

import All from './all/all';
import Connections from './connections/connections';
import NewIntegration from './new-integration/new-integration';

interface Values {
  all: boolean;
  connections: number[];
  new_domain: string | undefined;
  new_id: number | undefined;
  new_type_crm_id: number | undefined;
}

interface Mover {
  isMoverActive: boolean;
  setIsMoverActive: AppEnv.SetState<boolean>;
}

const Mover = ({isMoverActive, setIsMoverActive}: Mover) => {
  const {pushNotification} = useAppContext();
  const {getIntegrationList} = useHomeContext();
  const {integration} = useIntegrationContext();

  const {postInstanceChange, putInstances} = useRequest();
  const {t} = useTranslation();

  const closeMover = () => {
    setIsMoverActive(false);
    getIntegrationList();
  };

  const handleSubmit = async (values: Values) => {
    if (values.all) {
      if (values.new_domain && values.new_type_crm_id) {
        const {data} = await postInstanceChange(
          integration.integration_type_id,
          integration.domain,
          values.new_type_crm_id,
          values.new_domain
        );

        if (data) {
          closeMover();
          pushNotification(data.message);

          for (const entry of Object.values(data.errors)) {
            for (const error of entry) {
              pushNotification(t`Error`, error);
            }
          }
        }
      }

      return true;
    }

    for (const id of values.connections) {
      const {data} = await putInstances(id, {
        crm_id: values.new_id
      });

      if (data) {
        pushNotification(
          `${t`Connection`} ${id}`,
          t`The connection has been moved.`
        );
      }
    }

    closeMover();

    return true;
  };

  const initialValues: Values = {
    all: true,
    connections: [],
    new_domain: undefined,
    new_id: undefined,
    new_type_crm_id: undefined
  };

  const validationSchema = yup.object().shape({
    all: yup.bool(),
    connections: yup
      .array()
      .of(yup.number())
      .when('all', {is: false, then: () => yup.array().min(1)}),
    new_domain: yup.string().trim().required(),
    new_id: yup.number(),
    new_type_crm_id: yup.number().required()
  });

  return (
    <Popup isActive={isMoverActive} onClick={() => setIsMoverActive(false)}>
      <Title.H2 style={{marginBottom: 16}}>
        {t`Move connections to another integration`}
      </Title.H2>

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validationSchema={validationSchema}
      >
        <Form>
          <Wrapper gap={16} isColumn>
            <NewIntegration />
            <Connections />
            <All />
          </Wrapper>

          <Line />

          <Wrapper gap={8}>
            <SaveButton>{t`Save`}</SaveButton>

            <Button color="white" onClick={() => setIsMoverActive(false)}>
              {t`Cancel`}
            </Button>
          </Wrapper>
        </Form>
      </Formik>
    </Popup>
  );
};

export default Mover;
