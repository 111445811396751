import React, {useState} from 'react';
import {Button, Line, Popup, SaveButton, Title, Wrapper} from 'whatcrm-core';
import {Formik, Form} from 'formik';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';

import TariffId from '../tariff_id/tariff_id';
import {usePaymentsContext} from '../payments-context';
import {useWorkspaceContext} from 'workspace/workspace-context';
import * as AppEnv from 'app-env';

import Currency from './currency/currency';
import IsPartner from './is-partner/is-partner';
import IsSubscribed from './is-subscribed/is-subscribed';
import Quantity from './quantity/quantity';
import Result from './result/result';
import System, {Column} from './system/system';

interface LinkGenerator {
  isLinkGeneratorActive: boolean;
  setIsLinkGeneratorActive: AppEnv.SetState<boolean>;
}

interface Values {
  currency: string | undefined;
  is_partner: boolean;
  is_subscribed: boolean;
  quantity: number;
  system: Column | undefined;
  tariff_id: number | undefined;
}

const LinkGenerator = ({
  isLinkGeneratorActive,
  setIsLinkGeneratorActive
}: LinkGenerator) => {
  const {connection, rateList} = usePaymentsContext();
  const {crmList} = useWorkspaceContext();

  const [link, setLink] = useState<string>();
  const {t} = useTranslation();

  const handleSubmit = async (values: Values) => {
    const crm = crmList.find(
      item => item.id == connection.integration?.integration_type_id
    );

    const rate = rateList.find(item => item.value == values.tariff_id);

    const params = new URLSearchParams({
      ...(values.is_partner
        ? {partner_ref: connection.integration?.partner_id.toString() || ''}
        : {}),
      ...(values.quantity > 1 ? {quantity: String(values.quantity)} : {}),
      currency: values.currency || rate?.label.split(', ')[2] || '',
      domain: connection.integration?.domain || '',
      instance_id: connection.id.toString(),
      is_subscribed: values.is_subscribed ? '1' : '0',
      tariff_id: values.tariff_id?.toString() || '',
      type: crm?.title || ''
    });

    const res =
      `${process.env.REACT_APP_API}/payments/${values.system}?${params}`.toLowerCase();

    setLink(res);
  };

  const initialValues = {
    currency: undefined,
    is_partner: false,
    is_subscribed: false,
    quantity: 1,
    system: undefined,
    tariff_id: undefined
  };

  const validationSchema = yup.object().shape({
    currency: yup.string(),
    is_partner: yup.boolean(),
    is_subscribed: yup.boolean(),
    quantity: yup.number(),
    system: yup.string().required(),
    tariff_id: yup.number().required()
  });

  return (
    <Popup
      isActive={isLinkGeneratorActive}
      onClick={() => setIsLinkGeneratorActive(false)}
    >
      <Title.H2 style={{marginBottom: 16}}>{t`Generate payment link`}</Title.H2>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={false}
        onSubmit={handleSubmit}
        validate={() => (link ? setLink(undefined) : undefined)}
      >
        <Form>
          <Wrapper gap={16} isColumn>
            <System />
            <TariffId isMaxWidth />
            <Quantity />
            <Currency />
            {!!connection.integration?.partner_id && <IsPartner />}
            <IsSubscribed />
            {link && <Result link={link} />}
          </Wrapper>

          <Line />

          <Wrapper gap={8}>
            <SaveButton>{t`Continue`}</SaveButton>

            <Button
              color="white"
              onClick={() => setIsLinkGeneratorActive(false)}
            >
              {t`Cancel`}
            </Button>
          </Wrapper>
        </Form>
      </Formik>
    </Popup>
  );
};

export default LinkGenerator;
