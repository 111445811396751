import React from 'react';
import {
  Button,
  Line,
  Popup,
  SaveButton,
  Text,
  Title,
  Wrapper
} from 'whatcrm-core';
import {Form, Formik} from 'formik';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';

import {Roles} from 'app-enums';
import {useAppContext} from 'app-context';
import * as AppEnv from 'app-env';

import DateTrial from './date-trial/date-trial';
import Domain from './domain/domain';
import IntegrationTypeId from './integration-type-id/integration-type-id';
import IsIntegration from './is-integration/is-integration';
import IsPremium from './is-premium/is-premium';
import IsReview from './is-review/is-review';
import TelphinCode from './telphin-code/telphin-code';
import WidgetCode from './widget-code/widget-code';

interface Editor {
  integration?: AppEnv.Integration;
  isActive: boolean;
  isUpdate?: boolean;
  onClick: () => void;
  onSubmit: AppEnv.Submit<AppEnv.IntegrationForm>;
}

const Editor = ({
  integration,
  isActive,
  isUpdate,
  onClick,
  onSubmit
}: Editor) => {
  const {client} = useAppContext();
  const {t} = useTranslation();

  const initialValues: AppEnv.IntegrationForm = integration || {
    domain: undefined,
    integration_type_id: undefined,
    is_integration: undefined,
    is_premium: undefined,
    is_review: undefined,
    telphin_code: undefined
  };

  const validationSchema = yup.object().shape({
    domain: yup.string().trim().required(),
    integration_type_id: yup.number().required()
  });

  const isIsIntegrationShown =
    client?.access_level && client.access_level >= Roles.PARTNER;

  return (
    <Popup isActive={isActive} onClick={onClick}>
      <Wrapper gap={4} isColumn style={{marginBottom: 16}}>
        <Title.H2>
          {isUpdate ? t`Update the integration` : t`Add an integration`}
        </Title.H2>

        {integration?.domain && <Text isParagraph>{integration.domain}</Text>}
      </Wrapper>

      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validationSchema={isUpdate ? undefined : validationSchema}
      >
        <Form>
          <Wrapper gap={16} isColumn>
            {isUpdate ? (
              <>
                <DateTrial />
                <WidgetCode />
              </>
            ) : (
              <>
                <Domain />
                <IntegrationTypeId />
              </>
            )}

            <TelphinCode />
            <IsPremium />
            {isIsIntegrationShown && <IsIntegration />}
            <IsReview />
          </Wrapper>

          <Line />

          <Wrapper gap={8}>
            <SaveButton>{t`Save`}</SaveButton>

            <Button color="white" onClick={onClick}>
              {t`Cancel`}
            </Button>
          </Wrapper>
        </Form>
      </Formik>
    </Popup>
  );
};

export default Editor;
