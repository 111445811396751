import {AxiosResponse} from 'axios';
import {useTranslation} from 'react-i18next';

import {useAppContext} from 'app-context';
import useInstance from './use-instance/use-instance';

interface Params {
  data?: object;
  method?: 'post';
  params?: object;
}

const useRequestKommo = (crm: string | undefined) => {
  const {pushNotification} = useAppContext();

  const {t} = useTranslation();
  const instance = useInstance(crm);

  const throwError = () => {
    const text = t`Something went wrong.`;
    pushNotification(t`Error`, text);

    return null;
  };

  const handleThen = <T>(res: AxiosResponse): T | null => {
    const {data} = res;
    if ('success' in data && !data.success) return throwError();

    return data;
  };

  // eslint-disable-next-line
  const handleCatch = (e: any) => {
    const text = t`Something went wrong.`;
    pushNotification(t`Error`, text);

    return null;
  };

  const request = async <T>(
    path: string,
    {data, method, params}: Params
  ): Promise<T | null> => {
    if (!instance) return throwError();

    const res = await instance(path, {data, method, params})
      .then(handleThen<T>)
      .catch(handleCatch);

    return res;
  };

  interface PrefsData {
    spam_protect: boolean;
  }

  interface Prefs {
    success: boolean;
    data: PrefsData;
  }

  const fetchPrefs = (domain: string, phone: string) =>
    request<Prefs>(`v1/prefs/${domain}`, {
      params: {phone, t: 'get_user_phone'}
    });

  const postPrefs = (domain: string, phone: string, data: PrefsData) =>
    request<Prefs>(`v1/prefs/${domain}`, {
      data,
      method: 'post',
      params: {phone, t: 'set_pref'}
    });

  return {fetchPrefs, postPrefs};
};

export default useRequestKommo;
