import React, {useEffect, useState} from 'react';
import {Checkbox} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {useIntegrationContext} from '../../integration-context';
import {useWorkspaceContext} from 'workspace/workspace-context';
import instanceTypes from '../instance-types';

const All = () => {
  const {crmList} = useWorkspaceContext();
  const {integration} = useIntegrationContext();

  const [isDisabled, setIsDisabled] = useState(false);

  const [field, , helpers] = useField<boolean>('all');
  const [newTypeCrmIdField] = useField<number | undefined>('new_type_crm_id');
  const {t} = useTranslation();

  useEffect(() => {
    const crm = crmList.find(
      item => item.id == integration.integration_type_id
    );

    const newCrm = crmList.find(item => item.id == newTypeCrmIdField.value);

    if (
      !crm ||
      !newCrm ||
      instanceTypes[crm.title] == instanceTypes[newCrm?.title] ||
      newCrm?.title == 'LK'
    ) {
      helpers.setValue(true);
      setIsDisabled(false);
      return;
    }

    helpers.setValue(false);
    setIsDisabled(true);
  }, [newTypeCrmIdField.value]);

  return (
    <Checkbox
      {...field}
      isDisabled={isDisabled}
      label={t`Move all connections and payments`}
      onChange={helpers.setValue}
    />
  );
};

export default All;
