import React from 'react';
import {ContextMenu} from 'whatcrm-core';

import {useOptions} from './hooks';

const Menu = () => {
  const options = useOptions();
  return <ContextMenu options={options} />;
};

export default Menu;
